.navbar-right,
.tabs {
  display: flex;
  gap: 20px
}

*,
.loader,
.loader:after {
  box-sizing: border-box
}

.nav-bar,
.text {
  padding-top: 20px
}

#MultiWayForm,
#oneWayForm {
  margin-left: 3%;
  margin-top: 15px
}

#departure-date,
#return-date {
  padding: 16px 10px 16px 44px
}

.link-text,
.panels,
.tabs {
  list-style-type: none
}

.flight-tab a,
.icon,
a.panel {
  text-decoration: none
}

.flight-tab a:hover::after,
.icon::after {
  left: 0;
  bottom: 0;
  content: "";
  position: absolute
}

body,
html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #eee
}

* {
  font-family: -apple-system, BlinkMacSystemFont, Arial, Helvetica Neue, Segoe UI, Roboto, sans-serif
}

.react-datepicker__input-container input {
  padding-left: 12px;
  padding-right: 0;
  min-height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  border-color: #ccc
}

optgroup,
select,
ul.tabs {
  font-weight: 700
}

#round-trip-start .row {
  width: 100%;
  gap: 15px
}

#round-trip-form-start {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%
}

header {
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
  width: 100%;
  margin: 0;
  background-color: #fff
}

.book-now {
  color: #555;
  font-weight: 800
}

.number {
  font-size: 18px
}

.dropdown-toggle,
.nav-link {
  font-size: 14px
}

.navbar-brand img {
  max-width: 140px;
  height: auto
}

.nav-link {
  margin-right: 15px
}

@media (max-width:992px) {
  .book-now {
    font-size: 12px
  }

  .number {
    font-size: 16px
  }
}

@media (max-width:768px) {
  .navbar-brand img {
    max-width: 100px
  }

  .nav-link {
    font-size: 12px;
    margin-right: 10px
  }

  .number {
    font-size: 14px
  }

  .book-now {
    font-size: 10px
  }
}

.book-now,
.sm-text {
  font-size: 1rem
}

.call-us {
  height: 40px;
  width: 346px;
  border: 1px solid green;
  border-radius: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center
}

.number {
  margin-left: 3px;
  color: green;
  font-weight: 400
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  margin: 0 200px
}

select {
  height: 30px;
  border: none;
  width: 70px;
  font-size: 15px
}

.navbar-right {
  align-items: center
}

.navbar-left,
.trip-time {
  display: flex
}

.tabs {
  justify-content: space-between;
  font-size: 1.2rem;
  align-items: center
}

.Sign-up,
.help {
  font-size: 1rem;
  font-weight: 900
}

.explore,
.text {
  font-weight: 700
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px
}

.bookings,
.panels,
.trip-type,
.trip-type-1 {
  display: flex
}

#Round-trip input[name=departure-date],
.helpline,
.react-datepicker-wrapper,
.title-text {
  width: 100%
}

.loader:after {
  content: " ";
  display: block;
  width: 64px;
  color: #00f;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent;
  animation: 1.2s linear infinite loader
}

@keyframes loader {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

.title-text {
  background-color: #002144;
  height: 240px;
  position: absolute
}

.bookings,
.explore,
.flight-panel,
.helpline,
.panels,
.text,
.trip-type {
  position: relative
}

.text {
  color: #f0f8ff;
  font-size: 2.5rem
}

.panels {
  color: #000;
  gap: 50px;
  text-align: left
}

.trip-text,
td,
tr {
  text-align: center
}

.bookings {
  background-color: #eef2f6;
  height: 41px;
  top: 20px;
  border-bottom: 1px solid #000;
  border-radius: 5px 5px 0 0
}

#MultiWayForm,
.dropdown-content,
.explore-trips,
.flight-form,
.flight-panel,
.helpline {
  background-color: #fff
}

a.panel {
  font-weight: 800;
  color: #000;
  font-size: 1.3rem
}

.flight-panel {
  width: 100%;
  margin-top: 19px;
  align-items: center;
  border-radius: 0 0 10px 10px;
  box-sizing: content-box
}

#coach,
.trip-type,
.trip-type-1 {
  margin-top: 10px
}

#arrival,
#departure,
#departure-date,
#return-date,
#travellers {
  border: 1px solid #73767f;
  height: 60px;
  font-size: 1rem
}

.airline-container,
.helpline,
.text2,
.title-text {
  box-sizing: border-box
}

.expanded {
  height: 350px
}

.trip-type-1 {
  color: #000;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 1rem;
  padding-left: 20px
}

#oneWayForm {
  display: none
}

#arrival,
#departure {
  margin-right: 20px;
  padding: 16px 36px 16px 44px
}

.mleft-4 {
  margin-left: 3rem
}

#departure-date {
  margin-right: 20px
}

#travellers {
  padding: 16px .8px 16px 44px
}

.in-row {
  max-width: 5%;
  margin-left: -6rem
}

#Search-flight {
  height: 60px;
  width: 500%;
  background-color: #ff4600;
  color: #fff;
  font-weight: 900;
  border-color: #ff4600
}

#arrival,
#departure {
  width: 140%
}

#Round-trip input[name=return-date],
#modify-trip input[name=departure-date] {
  width: 200px
}

#Round-trip input[name=travellers],
#modify-trip input[name=travellers] {
  margin-right: 5px
}

input {
  border-radius: 5px
}

.search {
  display: block;
  margin-left: 45px;
  margin-top: 20px
}

.search-airline {
  display: inline;
  border: none;
  outline: 0;
  border-bottom: 1px solid #000;
  font-size: 1rem
}

@media (max-width:1200px) {
  .helpline {
    max-width: 90%
  }

  .trips {
    max-width: 80%
  }
}

@media (max-width:768px) {
  .helpline {
    max-width: 100%;
    padding: 20px 10px
  }
}

@media (max-width:480px) {
  .helpline {
    height: auto;
    flex-direction: column;
    padding: 16px 8px
  }
}

.helpline,
.justify-content-between {
  justify-content: space-between
}

.helpline {
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 20px;
  border-radius: 10px
}

.justify-content-around {
  justify-content: space-around
}

.social-media,
.trips {
  justify-content: space-between
}

.airlines {
  display: flex;
  gap: 100px
}

.trip-text {
  margin-top: 55px
}

@media (max-width:992px) {
  .trips {
    max-width: 70%;
    padding: 20px 30px
  }
}

@media (max-width:768px) {
  .trips {
    max-width: 80%;
    padding: 15px 20px
  }

  header {
    width: 100%
  }

  .passenger-form .row {
    gap: 10px
  }

  .passenger-form .col-md-2 {
    flex-basis: 48%
  }

  .passenger-form .in-row {
    flex-basis: 100%;
    display: flex;
    justify-content: center
  }
}

@media (max-width:576px) {
  header {
    padding: 10px 0
  }

  .d-flex {
    flex-direction: column;
    align-items: flex-start
  }

  .trips {
    max-width: 100%;
    padding: 10px
  }
}

.low-color {
  color: green
}

.trips {
  display: flex;
  width: 102%;
  max-width: 102%;
  padding: 20px 70px;
  align-items: center
}

.explore {
  font-size: 1.25rem;
  width: 100%;
  max-width: 80%
}

.dropdown-content {
  display: none;
  position: absolute;
  border: 1px solid #ddd;
  z-index: 1;
  max-height: 150px;
  overflow-y: auto;
  width: 200px
}

.explore-trips,
.flight-tab,
.icon,
.price-bar-line,
.text2,
.trip-card {
  position: relative
}

.card,
.social-media {
  border-bottom: 1px solid #ccc
}

#departure-dropdown {
  top: 100%;
  left: .5%;
  width: 17%
}

#arrival-dropdown {
  top: 100%;
  left: 19.5%;
  width: 17%
}

.flight-form {
  display: flex;
  justify-content: center;
  height: 100px;
  padding-top: 10px
}

#modify-trip input[name=arrival],
#modify-trip input[name=departure] {
  width: 400px
}

#modify-trip input[name=return-date] {
  width: 200px;
  margin-right: 10px
}

.explore-trips {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 0;
  width: 100%;
  max-width: 80%;
  margin: 0 auto 200px;
  padding: 0 20px;
  justify-items: center
}

.links,
.social-media {
  display: flex;
  color: #fff
}

.card {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc
}

.price-indicator p.trip-text,
h3.city {
  text-overflow: ellipsis;
  width: 18rem;
  display: block
}

footer {
  padding-bottom: 4rem;
  background-color: #022d5c;
  padding-top: 1rem
}

.footer-container {
  margin: 0 50px 0 20px
}

.social-media {
  font-size: 1.5rem;
  padding-top: 30px
}

.icons span {
  margin-right: 10px
}

.links {
  justify-content: space-between;
  font-size: 1rem
}

.link-text {
  margin-bottom: 2px;
  display: grid;
  gap: 10px;
  font-size: 1rem
}

@media screen and (max-width:1800px) {

  #departure-date,
  #return-date,
  #travellers {
    height: 60px
  }

  #arrival,
  #departure {
    height: 60px;
    width: 230px
  }

  #travellers {
    width: 100px
  }

  .sm-text {
    font-size: .7rem
  }
}

@media screen and (max-width:1520px) {
  .main-container {
    margin: 0 5%
  }

  #arrival,
  #departure,
  #departure-date,
  #return-date,
  #travellers {
    height: 60px
  }

  #Search-flight {
    height: 60px;
    font-size: .8rem
  }

  #round-trip-start,
  .flight-panel {
    width: 100%
  }

  .passenger-form {
    margin-left: 10px;
    width: 100%;
    gap: 5px
  }
}

@media screen and (max-width:1350px) {
  .main-container {
    margin: 0 2%
  }
}

.name,
.stops {
  margin-right: 8px
}

@media screen and (max-width:1200px) {
  #round-trip-form-start .col-md-2 {
    flex: 1 1 calc(33.33% - 15px)
  }
}

.filter-container {
  width: 20px;
  padding: 0 10px 10px
}

.airline-panel,
table {
  width: 80%;
  background-color: #fff
}

.amount {
  float: right;
  font-weight: 200
}

.min,
.stops {
  font-weight: 900
}

.going {
  margin-bottom: 5px
}

.departing-from {
  margin-left: 5px
}

table {
  height: 20%;
  margin-left: 10px
}

td,
tr {
  border: .1px solid #73767f
}

.parent {
  display: flex;
  margin-top: 20px
}

.flight-table-container {
  flex-basis: 75%
}

.airline {
  padding-top: 30px
}

.airline-container {
  display: flex;
  gap: 150px
}

.airline-panel {
  height: 30%;
  display: flex;
  gap: 10%;
  border-radius: 15px
}

.stops {
  font-size: 1.2rem
}

.price-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px
}

.info {
  width: 270px
}

.min {
  color: #00f
}

.price-btn {
  height: 50px;
  width: 180px;
  background-color: #ff4600;
  color: #fff;
  font-size: 1rem;
  outline: 0;
  border: none;
  border-radius: 10px
}

.trip-card img,
.trip-image {
  height: 200px;
  object-fit: cover
}

.flight-times {
  margin: 25px 0
}

.suggestions-dropdown::-webkit-scrollbar {
  width: 0;
  background: 0 0
}

.suggestions-dropdown:hover {
  color: #44739a
}

.suggestions-dropdown li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee
}

.suggestions-dropdown li:last-child {
  border-bottom: none
}

.suggestions-dropdown li:hover {
  background-color: #f0f0f0
}

.main-container-flight h2 {
  font-size: 16px;
  color: #000;
  font-weight: 700
}

.main-container-flight .amount,
.main-container-flight .stop,
.main-container-flight h3 {
  font-size: 14px;
  color: #000;
  font-weight: 700
}

.stop-element {
  display: flex;
  align-items: baseline;
  justify-content: space-between
}

#Search-flight {
  font-size: 14px;
  padding: 0
}

.main-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px
}

.title-text {
  padding-left: 20px
}

.bookings,
.explore,
.helpline,
.text,
.trips {
  margin: 20px 0;
  padding-left: 20px;
  box-sizing: border-box
}

.text2 {
  color: #f0f8ff;
  font-size: 2.5rem;
  font-weight: 700;
  padding: 20px 0 0px 4px
}

.trip-image {
  width: 100%
}

.city {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px
}

.date-range,
.route {
  font-size: 14px;
  color: #555
}

.flight-tab a:hover,
.price {
  color: #000
}

.price-section {
  margin-top: 10px;
  font-size: 16px
}

.price {
  font-size: 20px;
  font-weight: 700
}

.high,
.low,
.trip-type {
  font-size: 14px;
  color: #888
}

.price-indicator {
  background-color: #f8f8f8
}

.price-bar {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.price-bar-line {
  width: 100px;
  margin: 0 10px
}

.bar {
  display: block;
  height: 5px;
  background-color: #f90;
  border-radius: 5px;
  width: 100%
}

.trips-container {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  scroll-snap-type: x mandatory;
  padding-bottom: 10px
}

.price-indicator,
.trip-content {
  padding: 16px;
  text-align: center
}

.trips {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px
}

.trip-card {
  min-width: 310px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  flex: 0 0 calc(33% - 20px);
  scroll-snap-align: start;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  background-color: #fff;
  overflow: hidden
}

.trip-card img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px
}

.trip-content h3.city {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0
}

.trip-content .date-range,
.trip-content .route {
  font-size: .9em;
  color: #777;
  margin-top: 4px
}

.trip-content .price-section {
  display: flex;
  justify-content: space-between;
  margin-top: 12px
}

.trip-content .price {
  font-size: 1.4em;
  font-weight: 700
}

.price-bar .high,
.price-bar .low,
.trip-content .trip-type {
  font-size: .9em;
  color: #777
}

.price-indicator {
  border-top: 1px solid #eee
}

.price-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0
}

.price-bar .price-bar-line {
  flex-grow: 1;
  margin: 0 10px;
  height: 6px;
  background: linear-gradient(to right, #0f0, #ff4600);
  border-radius: 3px
}

.trip-text {
  font-size: .85em;
  color: #777
}

.main-container-flight {
  display: flex;
  flex-direction: column;
  padding: 20px
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px
}

.filter-container>div {
  margin: 10px
}

.flight-table-container {
  overflow-x: auto
}

.flight-table {
  width: 100%;
  border-collapse: collapse
}

.flight-table td {
  padding: 10px;
  border: 1px solid #ddd
}

.airline-panel {
  display: flex;
  flex-direction: column
}

.airline {
  display: flex;
  justify-content: space-between
}

@media (max-width:768px) {
  #Search-flight {
    font-size: 14px;
    padding: 8px 16px
  }

  .main-container-flight {
    padding: 10px
  }

  .filter-container {
    flex-direction: column
  }

  .filter-container>div {
    margin: 5px 0
  }

  .flight-table-container {
    overflow-x: scroll
  }

  .airline {
    flex-direction: column;
    align-items: flex-start
  }
}

@media (max-width:480px) {
  .price-container {
    text-align: center
  }

  .price-btn {
    width: 100%
  }
}

.airline-panel {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 1px solid #ccc
}

.airline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}

.airline-container {
  flex: 1 1 100%;
  margin: 5px;
  border: 1px solid #eee
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #f9f9f9
}

@media (min-width:600px) {
  .airline-container {
    flex: 1 1 48%
  }
}

@media (min-width:900px) {
  .airline-container {
    flex: 1 1 30%
  }
}

.panels {
  display: flex;
  padding: 0;
  margin: 0
}

.flight-tab {
  margin-top: 10px;
  margin-right: 10px
}

.flight-tab a {
  color: #000;
  padding-bottom: 5px
}

.flight-tab a:hover::after {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #00f;
  margin-top: 5px
}

.header .number {
  font-size: 1.2rem
}

@media (max-width:576px) {
  .passenger-form .row {
    gap: 5px
  }

  .passenger-form .col-md-2 {
    flex-basis: 100%;
    margin-bottom: 10px
  }

  .passenger-form button {
    width: 100%;
    padding: 12px
  }

  #Search-flight {
    font-size: 12px;
    padding: 6px 12px
  }

  .header .number {
    font-size: 1.5rem
  }
}

.link-hover:hover {
  color: orange;
  cursor: pointer
}

.icon {
  cursor: pointer;
  display: inline-block
}

.icon::after {
  width: 75%;
  height: 1px;
  background: #fff;
  transform: scaleX(0);
  transition: transform .3s
}

.icon:hover::after {
  transform: scaleX(1);
  cursor: pointer
}

.blueLine {
  width: 5px;
  background: #006ee3;
  border-radius: 25px;
  height: 80px;
  margin-right: 20px
}

.fontSizeStops {
  margin: "0";
  font-size: 14px;
  font-weight: 600
}

.progress-bar-animated {
  -webkit-animation: .1s linear infinite progress-bar-stripes;
  animation: .1s linear infinite progress-bar-stripes
}

.customBookNowWidth {
  width: 55%
}


 .sticky-button {
   position: fixed;
   bottom: 10px;

   background-color: #c89807;
   color: white;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   z-index: 1000;
 }
 .customWidthbutton{
  width:88%;
  margin-inline: 3px;
      /* background-color: #9B4144; */
        border-color: #c89807;
 }

 .phone-context-wrap{
      display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 18px;
        color: #262933;
        letter-spacing: -.44px;
        line-height: 22px;
        font-weight: 700;
        white-space: nowrap;
 }

 .main-cont{
  text-align: left;
    display: flex;
 }
 .first-line-text{
  margin-left: 1rem;
      font-size: 14px;
        color: #262933;
        letter-spacing: -.31px;
        line-height: 20px;
        font-weight: 400;
        white-space: nowrap;
 }
 .logo-text{
  padding: 0px ;
 }

 .phone-link{
      text-decoration: none;
 }

 .sticky-button:hover {
   background-color: #c89807;
 }
.Customselect {
  border: 0;
  box-shadow: none
}

.Customselect option {
  color: grey
}

.Customselect option:hover {
  background: #f0f0f0 !important
}

.Customselect option:checked {
  background-color: #bcd9f8;
  color: #006ee3
}

.Customselect option[selected] {
  color: #00f
}

@media (max-width:1052px) {
  .customBookNowWidth {
    width: 50%
  }
}

@media (max-width:947px) {
  .customBookNowWidth {
    width: 37%
  }
}

@media (max-width:767px) {
  .title-text {
    height: 270px
  }

  .trip-type-1 {
    padding-left: 0;
    width: 100%;
    justify-content: space-around
  }

  .headerSmallScreen {
    justify-content: space-around
  }
}

@media (max-width:577px) {

  .rotateImageArrow,
  .rotateImageArrow2 {
    transform: rotate(90deg);
    position: absolute;
    z-index: 4
  }

  .customWidth,
  .width100 {
    width: 100%
  }

  .ml0 {
    margin-left: 0 !important
  }

  .rotateImageArrow {
    top: 107px
  }

  .rotateImageArrow2 {
    top: 154px
  }

  .inputDateWidth {
    width: 49%
  }

  .customClassDatePick {
    width: 100%;
    justify-content: space-between
  }

  .smallScreenDisplay {
    display: flex;
    width: 100%;
    justify-content: space-between
  }

  .fontSizeStops {
    margin: "0";
    font-size: 12px
  }

  .text2 {
    font-size: 20px
  }
}

@media (max-width:450px) {
  .inputDateWidth {
    width: 49%;
    margin-bottom: 8px
  }

  .customWidth {
    width: 100%;
    margin-bottom: 8px
  }

  .customClassDatePick {
    width: 100%;
    justify-content: space-between
  }
}

@media (max-width:400px) {
  .title-text {
    height: 400px
  }
}

.cursor-pointer{
  cursor: pointer;
}

.privacy-card{
  margin-top: 4rem;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px;
}
.privacy-card h3{
  font-weight: bold;
  text-decoration: underline;
}
.privacy-head {
  font-weight: bold;

}
.bookings{
  margin-top: -1rem;
}

.font-bold{
  font-weight: bold;
  color: rgb(101, 100, 100);
}

input::placeholder{
  font-weight: bold;
  color: #000;
}
.cont-booknow{
  border: 1px solid black;
    border-radius: 1rem;
    display: flex;
    gap: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    cursor: pointer;
}

#content-privacy{
      padding-left: 3rem;
        padding-right: 5rem;
        padding-top: 2rem;
        text-transform: capitalize;
}

#content-privacy a{
  text-transform: none;
}
button.customWidth {
  background-color: #9B4144;
  border-color: #9B4144;
  font-size: 17px;
}
button.customWidth:hover {
  box-sizing: border-box;
  background-color: #9B4144;
  border-color: #9B4144;
  font-size: 17px;
}

@media (max-width:392px) {
  .rotateImageArrow {
    top: 150px
  }
}