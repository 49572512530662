/*  */
#suggestion , #arrival-suggestion{
  list-style-type: none; /* Remove bullets */
  padding: 0;
  margin: 0;
  background-color: white;
  border: 1px solid #ccc; /* Border around the dropdown */
  position: absolute; /* Make the dropdown appear right below the input */
  z-index: 1000; /* Ensure the dropdown appears above other content */
  max-height: fit-content; /* Limit height */
  overflow: hidden;
  overflow-y: scroll; /* Add scroll if content exceeds height */
  width: max-content; /* Make it the same width as the input */
}